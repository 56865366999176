import React from "react";
import {Route, Routes} from "react-router-dom";
import NavBar from "./Components/organs/NavBar";
import BlogComp from "./Components/pages/BlogComp";
import Home from "./Components/pages/Home";
import {ToastContainer} from "react-toastify";
import SlugArticleComp from "./Components/pages/SlugArticleComp";
import ErrorPage from "./Components/pages/Error";
import {SigninForm} from "./Components/molecules/SigninForm.tsx";
import {RegisterForm} from "./Components/molecules/RegisterForm.tsx";
import TaskRL from "./Components/RL/TaskRL";
import MenuEditor from "./Components/StructureManagement/MenuEditor";
import MarkdownToSpeech from "./Components/Projects/AudioGen/MarkdownToSpeech";

let isLocalRun = false;
const hostname = window.location.hostname;
if (hostname === "localhost" || hostname === "127.0.0.1") {
	isLocalRun = true;
}

function App() {
	function handleClosePrivacyModal() {
		this.setState({privacyModalOpen: false});
		// localStorage.setItem('privacyAccepted', true);
		// console.log('privacyAccepted', localStorage.getItem('privacyAccepted'))
	}
	
	// if (localStorage.getItem('privacyAccepted') == 'false') {
	// 	window.location.href = 'https://applyjobs.ai/privacypolicy';
	// }
	// console.log('privacyAccepted', localStorage.getItem('privacyAccepted'));
	// console.log('privacyModalOpen', this.state.privacyModalOpen);
	
	return (
		<div className="app-container">
			{/*<Routes>*/}
			{/*	<Route*/}
			{/*		path="/paid-blog"*/}
			{/*		element={<ProtectedRoute element={<PaidBlog/>}/>}*/}
			{/*	/>*/}
			{/*	<Route*/}
			{/*		path="/paid-member-chats"*/}
			{/*		element={<ProtectedRoute element={<PaidMemberChats/>}/>}*/}
			{/*	/>*/}
			{/*</Routes>*/}
			<NavBar isLocalRun={isLocalRun}/>
			<Routes>
				<Route path="/" element={<Home/>}/>
				<Route path="/auth/signin" element={<SigninForm/>}/>
				<Route path="/auth/register" element={<RegisterForm/>}/>
				<Route path="*" element={<ErrorPage/>}/>
				<Route path="/course/:courseId" element={<BlogComp useremail={"published@neugence.ai"}/>}/>
				<Route path="/article/:slug_id" element={<SlugArticleComp/>}/>
				<Route path="/tasks_rl" element={<TaskRL/>}/>
				{/* Wrap MenuEditor with DndProvider */}
				<Route path="/menueditor" element={<MenuEditor/>}/>
				<Route path="/markdowntospeech" element={<MarkdownToSpeech/>}/>
				
			</Routes>
			{/*<NewFooter/>*/}
			<ToastContainer/>
			
			{/*DO NO DELETE*/}
			{/*New Responsibilities being transitioned here to this repo*/}
			{/*{this.state.privacyModalOpen && <PrivacyPopup onClose={this.handleClosePrivacyModal}/>}*/}
			{/*// var signedin = sessionStorage.getItem("signedin");*/}
			{/*// const privacyAccepted = localStorage.getItem('privacyAccepted');*/}
			{/*// console.log('privacyAccepted', localStorage.getItem('privacyAccepted'))*/}
			{/*// if (!privacyAccepted) {*/}
			{/*// 	this.setState({privacyModalOpen: false})*/}
			{/*// }*/}
		</div>
	);
}

export default App;
