import React, { useState } from 'react';
import './MarkdownToSpeech.css'; // Import the CSS file for styling

const MarkdownToSpeech = () => {
  const [markdownInput, setMarkdownInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [generatedText, setGeneratedText] = useState(null); // State to store generated text
  const [audioUrl, setAudioUrl] = useState(null); // State to store audio URL

  const handleInputChange = (e) => {
    setMarkdownInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setGeneratedText(null);
    setAudioUrl(null);

    try {
      const response = await fetch('https://mailchamp.ai:5005/markdown_speak', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ markdown_input: markdownInput }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate speech. Please try again.');
      }

      const data = await response.json();
      setGeneratedText(data.generated_text);

      // Create a URL for the base64 encoded audio
      const audioUrl = `data:audio/wav;base64,${data.audio_base64}`;
      setAudioUrl(audioUrl);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginTop: '100px', marginLeft: '150px' }}>
      <h1>Markdown to Speech Converter</h1>
      <form onSubmit={handleSubmit}>
        <textarea
          rows="10"
          cols="60"
          placeholder="Enter Markdown text here..."
          value={markdownInput}
          onChange={handleInputChange}
          style={{ border: '1px solid lightgrey', padding: '10px' }}
        />
        <br />
        <button
          type="submit"
          disabled={loading}
          style={{
            border: loading ? 'none' : '1px solid lightgrey', // Conditionally remove border
            borderRadius: '20px',
            padding: '5px',
            minHeight: '40px',
            minWidth: '160px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative', // Position relative for absolute spinner
            backgroundColor: loading ? 'transparent' : '#fff', // Transparent background when loading
            cursor: loading ? 'default' : 'pointer', // Change cursor to default when loading
            overflow: 'hidden', // Hide overflow for the moving spinner
          }}
        >
          {loading ? <div className="cool-ai-spinner"></div> : 'Convert to Speech'}
        </button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Show generated text */}
      {generatedText && (
        <div style={{ marginTop: '20px' }}>
          <h3>Generated Text:</h3>
          <textarea
            rows="6"
            cols="60"
            value={generatedText}
            style={{ border: '1px solid lightgrey', padding: '10px' }}
            disabled={true}
          />
        </div>
      )}

      {/* Show the audio player if the audio URL is available */}
      {audioUrl && (
        <div style={{ marginTop: '20px' }}>
          <h3>Generated Speech:</h3>
          <audio controls>
            <source src={audioUrl} type="audio/wav" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )}
    </div>
  );
};

export default MarkdownToSpeech;
