// ... other imports
import { useEffect, useState } from "react";
import { Slide } from "react-awesome-reveal";

import Dropdown from "./Dropdown";
import NavItem from "./NavItem";
import menuStore from "./store/menuStore";
import { useLocation, useNavigate } from "react-router-dom";
import { FaCaretLeft } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";

const NavBar = ({ isLocalRun }) => {
  const { menuData, fetchMenuData } = menuStore((state) => ({
    menuData: state.menuData,
    fetchMenuData: state.fetchMenuData,
  }));
  const location = useLocation();
  const pathname = location.pathname;
  

  const [menuOpen, setMenuOpen] = useState(false);

  // Zustand Get Data from Backend
  useEffect(() => {
    fetchMenuData();
  }, []);

  const allItems = Object.entries(menuData).flatMap(([sectionKey, section]) =>
    Object.entries(section).flatMap(([subCategoryKey, items]) =>
      Object.entries(items).map(([itemKey, item]) => ({
        ...(item as any),
        label: itemKey,
        section: sectionKey,
        subCategory: subCategoryKey,
      }))
    )
  );

  const getSiblings = (slug) => {
    for (const section of Object.values(menuData)) {
      for (const items of Object.values(section)) {
        const itemList = Object.values(items);
        const itemIndex = itemList.findIndex((item: any) => item.slug === slug);

        if (itemIndex !== -1) {
          return itemList.map((item: any) => (
            <SubNavItem
              key={item.slug}
              item={item}
              label={Object.keys(items).find(
                (key) => items[key].slug === item.slug
              )}
            />
          ));
        }
      }
    }
    return null;
  };

  if(pathname.startsWith('/auth')) return null;
  
  return (
    <div className="flex items-center justify-center w-screen fixed top-0 left-0 bg-white bg-opacity-50 backdrop-blur-sm border-b z-40">
      <header
        style={{
          width: "100%", // Set the width
          maxWidth: "1400px", // Set the max width
          boxSizing: "border-box", // Include padding/border in the width
        }}
      >
        <Slide
          duration={location.pathname === "/" ? 500 : 0}
          direction="down"
          className="z-10"
        >
          <nav
            className="relative flex justify-between items-center h-14 px-12 overflow-visible"
            style={{ maxWidth: "1400px" }}
          >
            <div className="flex items-center gap-12">
              <div className="flex items-center">
                <a
                  href="/"
                  className="font-bold text-gray-800 text-xl md:text-2xl"
                >
                  <span className="text-color7">
                    Ace<span className="text-color2">Hub</span>.ai
                  </span>
                </a>
              </div>
            </div>

            <div
              className={`xl:px-0 max-w-[65vw] xl:h-12 hidden xl:top-0 xl:py-0 xl:w-fit xl:flex flex-col xl:flex-row gap-4 items-center xl:relative z-20`}
            >
              {Object.keys(menuData).map((key) => {
                const item = menuData[key];

                return (
                  <NavItem key={key} label={key}>
                    <Dropdown setOpen={setMenuOpen} items={item} />
                  </NavItem>
                );
              })}
            </div>

            <div className="flex gap-2 items-center">
              <div className="xl:hidden">
                <button
                  onClick={() => setMenuOpen(!menuOpen)}
                  className="p-2 focus:outline-none"
                >
                  {menuOpen ? <IoClose /> : <RxHamburgerMenu />}
                </button>
              </div>
              {menuOpen && (
                <div
                  style={{ WebkitBackdropFilter: "blur(10px)" }}
                  className="xl:hidden w-screen h-[96vh] top-14 fixed left-0 bg-black bg-opacity-30 z-10 backdrop-blur-md"
                  onClick={() => setMenuOpen(false)}
                />
              )}
              <div
                className={`${
                  menuOpen
                    ? "transform translate-x-0 !items-start !justify-start"
                    : "transform -translate-x-full"
                } xl:px-0 flex mt-14 h-[95vh] xl:h-auto xl:top-14 top-0 xl:mt-0 left-0 overflow-y-auto absolute bg-white px-4 py-4 w-[85vw] xl:w-fit transition-all duration-100 xl:py-0 xl:hidden flex-col xl:flex-row gap-4 items-center xl:relative z-20 justify-center`}
              >
                {Object.keys(menuData).map((key) => {
                  const item = menuData[key];

                  return (
                    <NavItem key={key} label={key}>
                      <Dropdown setOpen={setMenuOpen} items={item} />
                    </NavItem>
                  );
                })}

                {/*<div className="flex gap-2 absolute bottom-24">*/}
                {/*  <button className="lg:hidden block font-semibold px-4 py-2 rounded-xl hover:bg-gray-300 transition duration-100">*/}
                {/*    Login*/}
                {/*  </button>*/}
                {/*  <button className="lg:hidden block font-semibold px-4 py-2 rounded-xl bg-gray-100 hover:bg-gray-300 transition duration-100">*/}
                {/*    Register*/}
                {/*  </button>*/}
                {/*</div>*/}
              </div>

              {/*<button className="hidden lg:block font-semibold px-4 py-2 rounded-xl hover:bg-gray-300 transition duration-100">*/}
              {/*  Login*/}
              {/*</button>*/}
              {/*<button className="hidden lg:block font-semibold px-4 py-2 rounded-xl bg-gray-100 hover:bg-gray-300 transition duration-100">*/}
              {/*  Register*/}
              {/*</button>*/}
            </div>
          </nav>
        </Slide>

        <nav
          className="
        md:px-10 flex no-scrollbar justify-between items-start h-8  group"
        >
          <div
            className={`no-scrollbar lg:px-0 lg:top-0 h-full lg:py-0 overflow-x-auto w-max lg:flex flex-col lg:flex-row gap-4 items-start lg:relative md:group-hover:z-10 md:-z-10 ${
              !menuOpen ? "z-10" : ""
            }`}
          >
            <div className="flex flex-row gap-2 h-full items-start ">
              {location.pathname.startsWith("/article/") && (
                <a href="/">
                  <FaCaretLeft />
                </a>
              )}
              {location.pathname.startsWith("/article/")
                ? getSiblings(location.pathname.replace("/article/", ""))
                : allItems.map((item) => (
                    <SubNavItem
                      key={item.slug}
                      item={item}
                      label={item.label}
                    />
                  ))}
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

function SubNavItem({ item, label }) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <button
      onClick={() => navigate(`/article/${item.slug}`)}
      key={item.slug}
      className={`${
        location.pathname === `/article/${item.slug}` ? "bg-gray-100" : ""
      } py-0.5 capitalize px-2 rounded-md font-semibold text-xs w-full text-nowrap cursor-pointer`}
    >
      {label}
      {item.premium && <span className="badge-plus ml-2">👑</span>}
    </button>
  );
}
export default NavBar;
