import React, { useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
} from '@hello-pangea/dnd';

// Import icons from Heroicons
import { PlusIcon, TrashIcon, PencilIcon } from '@heroicons/react/solid';

// Sample data
import { menudata } from './menudata';

const MenuEditor = () => {
  // Transform the nested tree data into a flat list
  const [treeData, setTreeData] = useState(flattenTreeFromObject(menudata.menudata));
  
  // State for modal visibility and current node being edited
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentNode, setCurrentNode] = useState(null);
  
  // Handle drag and drop
  const onDragEnd = (result) => {
    const { source, destination } = result;
    
    // Dropped outside the list
    if (!destination) {
      return;
    }
    
    const newTreeData = Array.from(treeData);
    const [movedItem] = newTreeData.splice(source.index, 1);
    newTreeData.splice(destination.index, 0, movedItem);
    
    setTreeData(newTreeData);
  };
  
  // Function to add a new node
  const addNode = (parentId) => {
    const newNode = {
      id: `node-${Date.now()}`,
      title: 'New Item',
      parentId: parentId,
      expanded: true,
      data: {},
    };
    setTreeData([...treeData, newNode]);
  };
  
  // Function to remove a node with confirmation
  const removeNode = (nodeId) => {
    if (window.confirm("Are you sure you want to delete this node and all its descendants?")) {
      // Remove node and all its descendants
      const nodesToRemove = getDescendants(treeData, nodeId);
      setTreeData(treeData.filter((node) => !nodesToRemove.includes(node.id)));
    }
  };
  
  // Function to update a node's data
  const updateNode = (updatedNode) => {
    setTreeData(
      treeData.map((node) =>
        node.id === updatedNode.id ? updatedNode : node
      )
    );
  };
  
  // Function to toggle node expansion
  const toggleNode = (nodeId) => {
    setTreeData(
      treeData.map((node) =>
        node.id === nodeId ? { ...node, expanded: !node.expanded } : node
      )
    );
  };
  
  // Function to expand all nodes
  const expandAll = () => {
    setTreeData(
      treeData.map((node) => ({ ...node, expanded: true }))
    );
  };
  
  // Function to collapse all nodes
  const collapseAll = () => {
    setTreeData(
      treeData.map((node) => ({ ...node, expanded: false }))
    );
  };
  
  // Define text colors for different levels
  const levelColors = [
    'black',      // Level 0
    'darkblue',   // Level 1
    'darkgreen',  // Level 2
    'darkred',    // Level 3
    'darkorange', // Level 4
    'purple',     // Level 5
  ];
  
  // Render tree nodes
  const renderTree = (nodes, parentId = null, level = 0) => {
    return nodes
      .filter((node) => node.parentId === parentId)
      .map((node, index) => (
        <React.Fragment key={node.id}>
          <Draggable draggableId={node.id} index={index}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={{
                  paddingLeft: level * 20 + 'px',
                  display: 'flex',
                  alignItems: 'center',
                  ...provided.draggableProps.style,
                }}
              >
                <span
                  {...provided.dragHandleProps}
                  style={{ marginRight: '5px', cursor: 'grab' }}
                >
                  &#9776;
                </span>
                {hasChildren(nodes, node.id) && (
                  <button onClick={() => toggleNode(node.id)}>
                    {node.expanded ? '^' : '>'}
                  </button>
                )}
                <span
                  onClick={() => toggleNode(node.id)}
                  style={{
                    marginLeft: '5px',
                    color: levelColors[level % levelColors.length],
                    cursor: 'pointer',
                  }}
                >
                  {node.title}
                </span>
                <button
                  onClick={() => {
                    setCurrentNode(node);
                    setIsModalOpen(true);
                  }}
                  style={{
                    marginLeft: '5px',
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                  }}
                  aria-label="Edit"
                >
                  <PencilIcon style={{ width: '16px', height: '16px', color: 'lightgrey' }} />
                </button>
                <button
                  onClick={() => addNode(node.id)}
                  style={{
                    marginLeft: '5px',
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                  }}
                  aria-label="Add Child"
                >
                  <PlusIcon style={{ width: '16px', height: '16px', color: 'grey' }} />
                </button>
                <button
                  onClick={() => removeNode(node.id)}
                  style={{
                    marginLeft: '5px',
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                  }}
                  aria-label="Remove"
                >
                  <TrashIcon style={{ width: '16px', height: '16px', color: 'lightgrey' }} />
                </button>
              </div>
            )}
          </Draggable>
          {node.expanded && renderTree(nodes, node.id, level + 1)}
        </React.Fragment>
      ));
  };
  
  // Modal component for editing node properties
  const Modal = ({ node, onClose }) => {
    const [title, setTitle] = useState(node.title || '');
    const [slug, setSlug] = useState(node.data.slug || '');
    const [premium, setPremium] = useState(node.data.premium || false);
    const [description, setDescription] = useState(node.data.description || '');
    
    const handleSubmit = (e) => {
      e.preventDefault();
      const updatedNode = {
        ...node,
        title,
        data: {
          ...node.data,
          slug,
          premium,
          description,
        },
      };
      updateNode(updatedNode);
      onClose();
    };
    
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '5px',
            width: '400px',
          }}
        >
          <h2>Edit Node</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Title:</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                style={{ width: '100%' }}
              />
            </div>
            <div>
              <label>Slug:</label>
              <input
                type="text"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
                style={{ width: '100%' }}
              />
            </div>
            <div>
              <label>Premium:</label>
              <input
                type="checkbox"
                checked={premium}
                onChange={(e) => setPremium(e.target.checked)}
              />
            </div>
            <div>
              <label>Description:</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{ width: '100%' }}
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <button
                type="submit"
                style={{ marginRight: '10px', padding: '5px 10px' }}
              >
                Save
              </button>
              <button
                type="button"
                onClick={onClose}
                style={{ padding: '5px 10px' }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };
  
  return (
    <div
      style={{ height: 'auto', marginTop: '100px', marginLeft: '100px' }}
    >
      <div style={{ marginBottom: '10px' }}>
        <button
          onClick={() => addNode(null)}
          style={{
            marginRight: '5px',
            border: 'none',
            background: 'none',
            cursor: 'pointer',
          }}
          aria-label="Add Root Node"
        >
          <PlusIcon style={{ width: '20px', height: '20px', color: 'grey' }} />
        </button>
        <button
          onClick={expandAll}
          style={{
            marginRight: '5px',
            border: '1px solid lightgrey',
            borderRadius: '20px',
            paddingLeft: '8px',
            paddingRight: '8px',
            color: 'grey',
          }}
        >
          Expand All
        </button>
        <button
          onClick={collapseAll}
          style={{
            border: '1px solid lightgrey',
            borderRadius: '20px',
            paddingLeft: '8px',
            paddingRight: '8px',
            color: 'grey',
          }}
        >
          Collapse All
        </button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="menu-tree" isDropDisabled={true}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {renderTree(treeData)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {isModalOpen && currentNode && (
        <Modal
          node={currentNode}
          onClose={() => {
            setIsModalOpen(false);
            setCurrentNode(null);
          }}
        />
      )}
    </div>
  );
};

export default MenuEditor;

/**
 * Helper Functions
 */

// Initialize node ID counter
let nodeIdCounter = 0;

// Flatten nested tree data into a flat list
function flattenTreeFromObject(obj, parentId = null, acc = []) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const id = `node-${nodeIdCounter++}`;
      const node = {
        id,
        title: key,
        parentId,
        expanded: true,
        data: {},
      };
      if (value && typeof value === 'object') {
        // Check if it's a leaf node
        if ('slug' in value || 'premium' in value || 'description' in value) {
          // It's a leaf node
          node.data = value;
          acc.push(node);
        } else {
          // Not a leaf node, has children
          acc.push(node);
          flattenTreeFromObject(value, id, acc);
        }
      } else {
        acc.push(node);
      }
    }
  }
  return acc;
}

// Check if a node has children
function hasChildren(nodes, nodeId) {
  return nodes.some((node) => node.parentId === nodeId);
}

// Get all descendant node IDs
function getDescendants(nodes, nodeId) {
  const descendants = [nodeId];
  nodes
    .filter((node) => node.parentId === nodeId)
    .forEach((child) => {
      descendants.push(...getDescendants(nodes, child.id));
    });
  return descendants;
}
