import React, { useEffect, useRef, useState } from 'react';
import { TypeAnimation } from 'react-type-animation';

const ScrollTypingEffect = () => {
  const [startTyping, setStartTyping] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setStartTyping(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.5 } // Adjust threshold to when the element is 50% in view
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer && observer.disconnect) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <div
      ref={elementRef}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {startTyping && (
        <TypeAnimation
          sequence={[
            'Generative Ai Projects',
            1000,
            () => {
            },
          ]}
          wrapper="span"
          cursor={false}
          style={{ fontSize: '1.6em', display: 'inline-block', fontFamily: 'Roboto, sans-serif',
	               background: 'linear-gradient(90deg, rgb(97 125 203) 30%, rgb(92 150 165) 60%, rgb(35, 116, 249) 100%) text',
                   WebkitBackgroundClip: 'text',
                   color: 'transparent'
			}}
          speed={200} // Slow typing speed
        />
      )}
    </div>
  );
};

export default ScrollTypingEffect;
