import { Image } from "../atoms/Image";
import { Text } from "../atoms/Text";
import { FooterTexts } from "../particles/DataLists";
import { List } from "../atoms/List";
import { Link } from "react-router-dom";
import {
  FacebookLogo,
  InstagramLogo,
  TwitterLogo,
  LinktreeLogo,
  LinkedinLogo,
  GithubLogo,
  TiktokLogo,
  SnapchatLogo,
  YoutubeLogo,
} from "@phosphor-icons/react";
// @ts-ignore
import app from "../../assets/appdownload.png";
// @ts-ignore
import React from "react";

const Footer = () => {
  return (
    <footer className="w-full flex flex-col mt-12 bg-color3">
      <section className="w-full h-auto grid lg:grid-cols-3 md:grid-cols-3 lg:px-20 md:px-12 px-6 md:py-16 py-4 gap-7 md:gap-4 lg:gap-0">
        
      <div className="lg:hidden flex flex-col items-start gap-4">
          <div className="">
            <a className="text-2xl font-bold text-white" href="/">
              Ace<span className="text-color2">Hub</span>.ai
            </a>
         
          <Text className="text-md text-white" as="p">
            By{" "}
            <a
              className="text-color2 font-semibold font-mono"
              href="https://neugence.ai"
            >
              Neugence.ai
            </a>
          </Text>
          </div>
          <Text className="text-xs text-white text-left" as="p">
            {FooterTexts.underLogoText}
          </Text>
        </div>

        <div className="flex flex-col md:items-left md:mx-auto md:max-w-[400px] gap-3">
          <Text className="text-lg text-color2 font-semibold" as="h2">
            {FooterTexts.quickLinks.caption}
          </Text>
          <ul className="flex flex-col gap-2 flex-wrap">
            {FooterTexts.quickLinks.links.map((link, index) => (
              <List key={index} className="text-sm">
                <Link
                  to={link.url}
                  className="text-white transition-all duration-300 hover:underline"
                >
                  {link.name}
                </Link>
              </List>
            ))}
          </ul>
        </div>

        <div className="lg:flex hidden flex-col items-center gap-4">
          <div className="">
            <a className="text-2xl font-bold text-white" href="/">
              Ace<span className="text-color2">Hub</span>.ai
            </a>
         
          <Text className="text-md text-white" as="p">
            By{" "}
            <a
              className="text-color2 font-semibold font-mono"
              href="https://neugence.ai"
            >
              Neugence.ai
            </a>
          </Text>
          </div>
          <Text className="text-xs text-white text-center" as="p">
            {FooterTexts.underLogoText}
          </Text>
        </div>

        <div className="flex flex-col md:items-left md:mx-auto md:max-w-[400px] gap-3">
          <Text className="text-lg text-color2 font-semibold" as="h2">
            {FooterTexts.contacts.caption}
          </Text>
          <ul className="flex flex-col gap-2">
            {FooterTexts.contacts.links.map((link, index) => (
              <List key={index} className="text-sm">
                <Link
                  to={link.url}
                  className="text-white transition-all duration-300 hover:underline"
                >
                  {link.name}
                </Link>
              </List>
            ))}
          </ul>
        </div>

        {/* <div className="flex flex-col lg:items-center gap-4 md:mt-8">
                    <Text className="text-xl text-white" as="h2">
                        {FooterTexts.more.caption}
                    </Text>
                    <ul className="flex flex-col gap-2 lg:ml-10">
                        {
                            FooterTexts.more.links.map((link, index) => (
                                <List key={index} className="text-sm">
                                    <Link to={link.url} className="text-white transition-all duration-300 hover:underline">{link.name}</Link>
                                </List>
                            ))
                        }
                    </ul>
                </div> */}


      </section>
      <div className="flex flex-col items-center w-full gap-4">
          <ul className="w-full flex items-center justify-center gap-4 flex-wrap">
            <List>
              <Link
                to={`https://facebook.com/neugence`}
                className="text-white border-[1px] border-color3/50 p-2.5 flex rounded-full transition-all duration-300 ease-in hover:bg-gradient-to-tr from-color2 to-color2 hover:text-black"
              >
                <FacebookLogo size={15} color="currentColor" weight="fill" />
              </Link>
            </List>
            <List>
              <Link
                to={`https://www.instagram.com/neugenceai`}
                className="text-white border-[1px] border-color3/50 p-2.5 flex rounded-full transition-all duration-300 ease-in hover:bg-gradient-to-tr from-color2 to-color2 hover:text-black"
              >
                <InstagramLogo size={15} color="currentColor" weight="fill" />
              </Link>
            </List>
            <List>
              <Link
                to={`https://twitter.com/neugenceai`}
                className="text-white border-[1px] border-color3/50 p-2.5 flex rounded-full transition-all duration-300 ease-in hover:bg-gradient-to-tr from-color2 to-color2 hover:text-black"
              >
                <TwitterLogo size={15} color="currentColor" weight="fill" />
              </Link>
            </List>
            <List>
              <Link
                to={`https://linktr.ee/neugence`}
                className="text-white border-[1px] border-color3/50 p-2.5 flex rounded-full transition-all duration-300 ease-in hover:bg-gradient-to-tr from-color2 to-color2 hover:text-black"
              >
                <LinktreeLogo size={15} color="currentColor" weight="fill" />
              </Link>
            </List>
            <List>
              <Link
                to={`https://linkedin.com/in/neugence`}
                className="text-white border-[1px] border-color3/50 p-2.5 flex rounded-full transition-all duration-300 ease-in hover:bg-gradient-to-tr from-color2 to-color2 hover:text-black"
              >
                <LinkedinLogo size={15} color="currentColor" weight="fill" />
              </Link>
            </List>
            <List>
              <Link
                to={`https://github.com/neugence`}
                className="text-white border-[1px] border-color3/50 p-2.5 flex rounded-full transition-all duration-300 ease-in hover:bg-gradient-to-tr from-color2 to-color2 hover:text-black"
              >
                <GithubLogo size={15} color="currentColor" weight="fill" />
              </Link>
            </List>
            <List>
              <Link
                to={`https://tiktok.com/@neugence`}
                className="text-white border-[1px] border-color3/50 p-2.5 flex rounded-full transition-all duration-300 ease-in hover:bg-gradient-to-tr from-color2 to-color2 hover:text-black"
              >
                <TiktokLogo size={15} color="currentColor" weight="fill" />
              </Link>
            </List>
            <List>
              <Link
                to={`https://www.snapchat.com/add/neugence`}
                className="text-white border-[1px] border-color3/50 p-2.5 flex rounded-full transition-all duration-300 ease-in hover:bg-gradient-to-tr from-color2 to-color2 hover:text-black"
              >
                <SnapchatLogo size={15} color="currentColor" weight="fill" />
              </Link>
            </List>
            <List>
              <Link
                to={`https://www.youtube.com/@neugence`}
                className="text-white border-[1px] border-color3/50 p-2.5 flex rounded-full transition-all duration-300 ease-in hover:bg-gradient-to-tr from-color2 to-color2 hover:text-black"
              >
                <YoutubeLogo size={15} color="currentColor" weight="fill" />
              </Link>
            </List>
          </ul>

          <Text as="p" className="text-base font-light text-white">
            Discover Our App
          </Text>
          <Image
            image={app}
            className="w-28"
            alt="App Download"
            as="a"
            href="/"
          />
        </div>
      <Text className="text-center text-gray-400 text-xs font-semibold py-6">
        Copyright 2024{" "}
        <a className=" text-white" href="/">
          Neugence <span className="text-color2">Technology</span> Pvt. Ltd.
        </a>
         All rights reserved.
      </Text>
    </footer>
  );
};

export default Footer;
