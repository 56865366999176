import React from "react";
import {FaPlay} from "react-icons/fa";
import {GiTeacher} from "react-icons/gi";
import {LiaChalkboardTeacherSolid} from "react-icons/lia";
import './Ribbon.css';

interface CardProps {
    course: {
        id: string;
        title: string;
        description: string;
        level: "Easy" | "Intermediate" | "Advanced";
        image: string;
        stats: { sections: number; chapters: number; projects: number; certifications: number; };
        hours: number;
        progress: number;
        background: string;
    };
    onClickSelfStudy?: (id: string) => void;
    onClickGroupClass?: (id: string) => void;
}

export default function CourseCard({course, onClickSelfStudy, onClickGroupClass}: CardProps) {
    const {id, image, stats, background, progress, hours, title, description, level} = course;

    const handleSelfStudyClick = () => {
        if (onClickSelfStudy && id) {
            onClickSelfStudy(id);
        }
    };

    const handleGroupClassClick = (course) => {
        if (onClickGroupClass && course) {
            onClickGroupClass(course);
        }
    };

    return (
        <div
            className="ribbon cursor-default shadow-md hover:brightness-110 duration-150 hover:shadow-xl hover:bg-[linear-gradient(rgba(255,255,160,0.1),rgba(0,0,150,0.9))] transition-all bg-origin-content rounded-3xl max-w-[350px] p-0 flex flex-col justify-between min-h-64 min-w-80"
            style={{
                backgroundImage: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.4)), url(${image})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <div className="px-5 py-4 flex flex-row items-center gap-4 justify-between text-white">
                <div className="flex flex-col gap-1 min-w-52">
                    <h1 className="font-semibold text-3xl pt-0 pl-0" style={{marginTop: "0px", textAlign: "left"}}>
                        {title}
                    </h1>
                    <h3 className="font-semibold text-sm text-gray-300">{description}</h3>
                    {/*<div className="flex">*/}
                    {/*  <button className="cursor-pointer font-semibold text-sm text-gray-300 underline justify-start flex"*/}
                    {/*          style={{fontSize:"10px"}}*/}
                    {/*          onClick={() => handleGroupClassClick(course)}>Ask 1:1 Guidance*/}
                    {/*  </button>*/}
                    {/*  <button className="pl-4 cursor-pointer font-semibold text-sm text-gray-300 underline justify-start flex"*/}
                    {/*          style={{fontSize:"10px"}}*/}
                    {/*          onClick={() => handleGroupClassClick(course)}>Join Group Class*/}
                    {/*  </button>*/}
                    {/*  <button className="pl-4 cursor-pointer font-semibold text-sm text-gray-300 underline justify-start flex"*/}
                    {/*          style={{fontSize:"10px"}}*/}
                    {/*          onClick={() => handleGroupClassClick(course)}>Ask Project*/}
                    {/*  </button>*/}
                    {/*</div>*/}
                    {/*<button onClick={handleGroupClassClick} className="pt-0 rounded-[15px] pl-0">*/}
                    {/*  <div*/}
                    {/*      className="pl-0 rounded-xl bg-white aspect-square text-black w-10 h-10 flex items-center justify-center flex-col gap-1"*/}
                    {/*      style={{marginTop: "-20px", border: "1px solid lightgrey", marginLeft: "-30px"}}*/}
                    {/*  >*/}
                    {/*    <div style={{display: "flex", marginTop: "0px"}}>*/}
                    {/*      <LiaChalkboardTeacherSolid style={{color: "black"}}/>*/}
                    {/*    </div>*/}
                    {/*    /!*<span style={{ color: "black", fontSize: "10px", marginTop: "0px" }}>Instructors</span>*!/*/}
                    {/*  </div>*/}
                    {/*</button>*/}
                </div>
            </div>

            <div className="bg-white shadow-[inset_0_-2px_5px_rgba(0,0,0,0.15)] min-h-[60px] items-center justify-between rounded-b-[20px] pl-3 pr-2 py-2 flex">
                <div className="flex flex-col gap-2 px-1 py-0.5">
                    <div className="flex flex-row gap-8">
                        <div className="flex flex-col items-center">
              <span className="text-color3 font-semibold" style={{fontSize: "30px"}}>
                {stats.sections}
              </span>
                            <h4 className="text-[#FA9C10] font-medium text-sm">Chapters</h4>
                        </div>

                        <div className="flex flex-col items-center">
              <span className="text-color3 font-semibold" style={{fontSize: "30px"}}>
                {stats.chapters}
              </span>
                            <h4 className="text-[#FA9C10] font-medium text-sm">Items</h4>
                        </div>

                        <button onClick={handleSelfStudyClick} className="pt-0 rounded-[15px] pl-10">
                            <div
                                className="pl-0 rounded-xl bg-white aspect-square text-black w-16 h-18 flex items-center justify-center flex-col gap-1"
                                style={{marginTop: "-27px", border: "4px solid lightgrey"}}
                            >
                                <FaPlay style={{color: "black", marginTop: "10px"}}/>
                                <span style={{color: "black", fontSize: "12px", marginTop: "0px"}}>Start</span>
                            </div>
                            <h3
                                className="font-semibold text-green-900 text-sm text-center mt-2 ml-0 pl-0"
                                style={{marginLeft: "-10px"}}
                            >
                                {level}
                            </h3>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
