import React from "react";
import MCQ from "./MCQ";
import CustomImage from "./CustomImage";
import CodeBlock from "../CodeBlock";

export const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");

      const extractText = (children) => {
        return React.Children.toArray(children).reduce((acc, child) => {
          if (typeof child === "string") {
            return acc + child;
          } else if (React.isValidElement(child) && child.props.children) {
            return acc + extractText(child.props.children);
          }
          return acc;
        }, "");
      };

      const codeContent = extractText(children);
      const code = codeContent.replace(/\n$/, "");

      return !inline && match ? (
        <CodeBlock code={code} language={match[1]} codeFolded={false}/>
      ) : (
        <code className={className} {...props}>
          {codeContent}
        </code>
      );
    },

    img: CustomImage,

    mcq: ({ node, ...props }) => {
      const { question, options, correctAnswer } = node.properties;
      const parsedOptions = JSON.parse(options);
      return (
        <MCQ
          question={question}
          options={parsedOptions}
          correctAnswer={correctAnswer}
        />
      );
    },
  };
