import { useState } from "react";
import DropdownItem from "./DropdownItem";
import { FaChevronRight } from "react-icons/fa";

const Dropdown = ({ items, setOpen }) => {
  const [openItems, setOpenItems] = useState({});

  const mouseEnter = (key, event) => {
    const windWidth = window.innerWidth;
    const el = event.currentTarget;
    const rect = el.getBoundingClientRect();

    const shouldOpen = rect.right + 600 > windWidth;
    const shouldTop = rect.top + 500 > window.innerHeight / 1.4;

    setOpenItems((prev) => ({
      ...prev,
      [key]: { open: true, left: shouldOpen, top: shouldTop },
    }));
  };

  const mouseExit = (key) => {
    setOpenItems((prev) => ({ ...prev, [key]: { ...prev[key], open: false } }));
  };

  return (
    <div className="bg-gray-100 shadow-lg xl:w-auto w-[73vw] p-1 z-30 rounded-lg xl:rounded-2xl mt-2 xl:absolute xl:left-0 xl:top-0">
      {Object.keys(items).map((key) => {
        const item = items[key];
        const hasChildren =
          typeof item === "object" &&
          !Array.isArray(item) &&
          !item.slug &&
          Object.keys(item).length >= 1;
        if (!hasChildren && key === "orderIndex") return null;

        const isOpen = openItems[key]?.open;
        const shouldOpenLeft = openItems[key]?.left;
        const shouldOpenTop = openItems[key]?.top && Object.keys(item).length >= 5;

        return (
          <div
            onMouseEnter={(event) => mouseEnter(key, event)}
            onMouseLeave={() => mouseExit(key)}
            key={key}
            className="cursor-pointer px-4 py-2 xl:py-3 hover:bg-gray-200 rounded-md xl:rounded-xl min-w-[180px] xl:w-[250px] relative"
          >
            <div
              onClick={(e) => {
                if (isOpen) {
                  mouseExit(key);
                } else {
                  mouseEnter(key, e);
                }
              }}
              className="flex gap-3 items-center justify-between font-semibold text-sm"
            >
              {key}{" "}
              <span className="text-xs">
                {hasChildren && (
                  <FaChevronRight
                    className={`transition duration-100 ${
                      isOpen ? "rotate-90" : ""
                    }`}
                  />
                )}
              </span>
            </div>
            {hasChildren && isOpen && (
              <div
                className={`pl-4 z-20 leading-snug absolute ${
                  shouldOpenTop ? "top-10 xl:-top-36" : "top-10 xl:-top-3"
                } ${
                  shouldOpenLeft ? "xl:left-[-38rem]" : "xl:left-[100%]"
                } md:left-6 md:max-w-[500px] max-w-[90vw] z-20 left-0`}
              >
                <div className="max-h-[600px] xl:min-w-[300px] min-w-[250px] xl:grid xl:grid-cols-2 xl:w-[600px] overflow-y-auto bg-gray-50 shadow-md rounded-lg p-1">
                  {Object.keys(item).map((sub) => {
                    if (sub)
                      return (
                        <DropdownItem
                          setOpen={setOpen}
                          key={sub}
                          label={sub}
                          item={item[sub]}
                        />
                      );
                    else return null;
                  })}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Dropdown;
