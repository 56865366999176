// @ts-ignore
import {Envelope} from "@phosphor-icons/react";
import {Input} from "../atoms/Input";
import {Button} from "../atoms/Button";
// import * as React from 'react'
import * as React from "react";
import {useEffect, useState} from "react";
// @ts-ignore
import {getAxiosBaseUrl} from "../../config";
// @ts-ignore
import {toast} from "react-toastify";
import {
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    getAuth,
    GithubAuthProvider,
    GoogleAuthProvider,
    signInWithPopup,
    TwitterAuthProvider
} from "firebase/auth";
import {app} from "../../firebase";
import {FaLock} from "react-icons/fa";
import {IoMail} from "react-icons/io5";
import axios from "axios";

// const auth = getAuth(app);
export const RegisterForm = ({onClose, isLocalRun}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    // const setCookie = (name, value, days, islocal = false) => {
    //     let expires = "";
    //     if (days) {
    //         const date = new Date();
    //         date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    //         expires = "; expires=" + date.toUTCString();
    //     }
    //     if (islocal) {
    //         document.cookie = name + "=" + (value || "") + expires + "; path=/; secure; SameSite=None; Domain=localhost";
    //     } else {
    //         document.cookie = name + "=" + (value || "") + expires + "; path=/; secure; SameSite=None; Domain=applyjobs.ai";
    //     }
    // }
    // const createUser = () => {
    //     createUserWithEmailAndPassword(auth, email, password)
    //         .then((userCredential) => {
    //             // Signed in
    //             const user = userCredential.user;
    //             // console.log(user);
    //             //alert("User Created");
    //             // Saving user info in local storage
    //             localStorage.setItem(
    //                 "quant_user",
    //                 JSON.stringify({
    //                     email: user.email,
    //                     uid: user.uid,
    //                 })
    //             );
    //             axios.defaults.baseURL = getAxiosBaseUrl();
    //             axios.post('get_custom_token_firebase', {
    //                 'userid': user.uid
    //             }).then(response => {
    //                 const customToken = response.data.token
    //                 // console.log('customToken', customToken);

    //                 setCookie('customToken', customToken, 1, isLocalRun);
    //                 if (isLocalRun) {
    //                     window.location.href = 'http://localhost:3001/'
    //                 } else {
    //                     window.location.href = 'https://app.applyjobs.ai';
    //                 }

    //                 // if (response.data.status == 200) {
    //                 // console.log("Token set in HTTP-only cookie");
    //                 // console.log(document.cookie);
    //                 // }
    //             }).catch((error) => {
    //                 console.error("Error setting token in cookie", error);
    //                 toast.error("Failed to set authentication token.");
    //             });
    //         })
    //         .catch((error) => {
    //             const errorCode = error.code;
    //             const errorMessage = error.message;
    //             console.log(errorCode, errorMessage);
    //             toast.error(errorMessage);
    //             alert(errorMessage);
    //         });
    // };

    // function onCloseInternal() {
    //     document.removeEventListener("keydown", handleEsc);
    //     onClose();
    // }

    // const handleEsc = (event: { keyCode: number }) => {
    //     if (event.keyCode === 27) {
    //         onCloseInternal();
    //     }
    // };
    // useEffect(() => {
    //     document.addEventListener("keydown", handleEsc);
    // }, []);

    // const googleProvider = new GoogleAuthProvider();
    // const signInWithGoogle = () => {
    //     signInWithPopup(auth, googleProvider)
    //         .then((result) => {
    //             const user = result.user;
    //             localStorage.setItem(
    //                 "quant_user",
    //                 JSON.stringify({
    //                     email: user.email,
    //                     uid: user.uid,
    //                 })
    //             );
    //             axios.defaults.baseURL = getAxiosBaseUrl();
    //             axios.post('get_custom_token_firebase', {
    //                 'userid': user.uid
    //             }).then(response => {
    //                 const customToken = response.data.token
    //                 // console.log('customToken', customToken);

    //                 setCookie('customToken', customToken, 1, isLocalRun);
    //                 if (isLocalRun) {
    //                     window.location.href = 'http://localhost:3001/'
    //                 } else {
    //                     window.location.href = 'https://app.applyjobs.ai';
    //                 }

    //                 // if (response.data.status == 200) {
    //                 // console.log("Token set in HTTP-only cookie");
    //                 // console.log(document.cookie);
    //                 // }
    //             }).catch((error) => {
    //                 console.error("Error setting token in cookie", error);
    //                 toast.error("Failed to set authentication token.");
    //             });
    //         })
    //         .catch((error) => {
    //             if (error.code === "auth/cancelled-popup-request") {
    //                 console.error("Firebase: Authentication popup request was canceled.");
    //                 toast.error("Authentication popup request was canceled.");
    //             } else {
    //                 console.error(
    //                     "Firebase: Error during authentication with Google:",
    //                     error
    //                 );
    //             }
    //         });
    // };

    // const githubProvider = new GithubAuthProvider();

    // const signInWithGitHub = () => {
    //     signInWithPopup(auth, githubProvider)
    //         .then((result) => {
    //             const user = result.user;
    //             localStorage.setItem(
    //                 "quant_user",
    //                 JSON.stringify({
    //                     email: user.email,
    //                     uid: user.uid,
    //                 })
    //             );
    //             axios.defaults.baseURL = getAxiosBaseUrl();
    //             axios.post('get_custom_token_firebase', {
    //                 'userid': user.uid
    //             }).then(response => {
    //                 const customToken = response.data.token
    //                 // console.log('customToken', customToken);

    //                 setCookie('customToken', customToken, 1, isLocalRun);
    //                 if (isLocalRun) {
    //                     window.location.href = 'http://localhost:3001/'
    //                 } else {
    //                     window.location.href = 'https://app.applyjobs.ai';
    //                 }

    //                 // if (response.data.status == 200) {
    //                 // console.log("Token set in HTTP-only cookie");
    //                 // console.log(document.cookie);
    //                 // }
    //             }).catch((error) => {
    //                 console.error("Error setting token in cookie", error);
    //                 toast.error("Failed to set authentication token.");
    //             });
    //         })
    //         .catch((error) => {
    //             console.error(
    //                 "Firebase: Error during authentication with GitHub:",
    //                 error
    //             );
    //             toast.error("Error signing in with GitHub. Please try again later.");
    //         });
    // };

    // const twitterProvider = new TwitterAuthProvider();

    // const signInWithTwitter = () => {
    //     signInWithPopup(auth, twitterProvider)
    //         .then((result) => {
    //             console.log("User signed in with Twitter:", result);

    //             const user = result.user;

    //             console.log("User signed in with Twitter:", user);
    //             localStorage.setItem(
    //                 "quant_user",
    //                 JSON.stringify({
    //                     email: user.email,
    //                     uid: user.uid,
    //                 })
    //             );
    //             axios.defaults.baseURL = getAxiosBaseUrl();
    //             axios.post('get_custom_token_firebase', {
    //                 'userid': user.uid
    //             }).then(response => {
    //                 const customToken = response.data.token
    //                 // console.log('customToken', customToken);

    //                 setCookie('customToken', customToken, 1, isLocalRun);
    //                 if (isLocalRun) {
    //                     window.location.href = 'http://localhost:3001/'
    //                 } else {
    //                     window.location.href = 'https://app.applyjobs.ai';
    //                 }

    //                 // if (response.data.status == 200) {
    //                 // console.log("Token set in HTTP-only cookie");
    //                 // console.log(document.cookie);
    //                 // }
    //             }).catch((error) => {
    //                 console.error("Error setting token in cookie", error);
    //                 toast.error("Failed to set authentication token.");
    //             });
    //         })
    //         .catch((error) => {
    //             console.error(
    //                 "Firebase: Error during authentication with Twitter:",
    //                 error
    //             );
    //             toast.error("Error signing in with Twitter. Please try again later.");
    //         });
    // };

    // const facebookProvider = new FacebookAuthProvider();

    // const signInWithFacebook = () => {
    //     signInWithPopup(auth, facebookProvider)
    //         .then((result) => {
    //             const user = result.user;
    //             localStorage.setItem(
    //                 "quant_user",
    //                 JSON.stringify({
    //                     email: user.email,
    //                     uid: user.uid,
    //                 })
    //             );
    //             axios.defaults.baseURL = getAxiosBaseUrl();
    //             axios.post('get_custom_token_firebase', {
    //                 'userid': user.uid
    //             }).then(response => {
    //                 const customToken = response.data.token
    //                 // console.log('customToken', customToken);

    //                 setCookie('customToken', customToken, 1, isLocalRun);
    //                 if (isLocalRun) {
    //                     window.location.href = 'http://localhost:3001/'
    //                 } else {
    //                     window.location.href = 'https://app.applyjobs.ai';
    //                 }

    //                 // if (response.data.status == 200) {
    //                 // console.log("Token set in HTTP-only cookie");
    //                 // console.log(document.cookie);
    //                 // }
    //             }).catch((error) => {
    //                 console.error("Error setting token in cookie", error);
    //                 toast.error("Failed to set authentication token.");
    //             });
    //         })
    //         .catch((error) => {
    //             console.error(
    //                 "Firebase: Error during authentication with Facebook:",
    //                 error
    //             );
    //             toast.error("Error signing in with Facebook. Please try again later.");
    //         });
    // };

    return (
        <main className="w-screen h-screen flex items-center md:py-8 lg:justify-around justify-center lg:flex-row flex-col">
          <div style={{backgroundImage: "url('https://ww2.kqed.org/app/uploads/sites/2/2023/04/Vincent-Favilla-_Pondering_.jpg')" }} className="hidden bg-no-repeat bg-cover bg-blend-overlay lg:flex w-3/5 h-full border border-gray-200 rounded-3xl bg-gray-200">
          <h1 className="text-xl font-semibold mt-4 ml-6">
              <span className="text-color7">
                Ace<span className="text-color2">Hub</span>.ai
              </span>
            </h1>
            </div>
          <div className="flex items-center justify-center flex-col">
          <h1 className="text-xl font-semibold mb-4 lg:hidden">
              <span className="text-color7">
                Ace<span className="text-color2">Hub</span>.ai
              </span>
            </h1>
            <h2 className="mb-5 text-2xl font-medium text-color7">Register to <span className="text-color7 font-medium">
                Ace<span className="text-color2">Hub</span>.ai
              </span></h2>
            <div>
              <form
                className="flex flex-col gap-4"
                onSubmit={(e) => {
                  e.preventDefault();
                  // signIn();
                }}
              >
                <div
                  className="flex md:flex-row flex-col gap-2"
                  style={{ justifyContent: "center" }}
                >
                  <Input
                    id="registrationEmail"
                    containerClass="relative"
                    inputClass="border rounded-lg outline-none  h-[50px] focus:outline-none text-color3 pr-4 pl-9 py-1"
                    style={{ width: "300px" }}
                    type="email"
                    placeholder="Email"
                    required={true}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  >
                    <div className="absolute top-4 left-3 text-color3/80">
                      <IoMail />
                    </div>
                  </Input>
                </div>
                <div
                  className="flex md:flex-row flex-col gap-2"
                  style={{ justifyContent: "center" }}
                >
                  <Input
                    id="registrationPassword"
                    containerClass="relative"
                    inputClass="border rounded-lg outline-none h-[50px] focus:outline-none text-color3 pr-4 pl-9 py-1"
                    style={{ width: "300px" }}
                    type="password"
                    placeholder="Password"
                    required={true}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  >
                    <div className="absolute top-4 left-3 text-color3/80">
                      <FaLock />
                    </div>
                  </Input>
                </div>
                <div className="flex md:flex-row flex-col justify-center gap-2">
                  <Button
                    type="submit"
                    className="border w-full justify-center outline-none bg-color2 py-2 px-6 text-white  text-base rounded-lg  hover:transition-colors hover:text-white duration-300 hover:border-b-2 hover:border-black font-bold hover:shadow-lg"
                  >
                    Sign up
                  </Button>
                </div>
              </form>
            </div>

            <div className="flex justify-center my-4 w-[30vw] items-center">
              <div className="border-b w-1/4 opacity-30 border-gray-800"></div>
              <div className="mx-4 text-gray-600 ">or</div>
              <div className="border-b w-1/4 opacity-30  border-gray-800"></div>
            </div>
    
            <div className="flex flex-col items-center justify-center gap-4 mt-4 mb-2 lg:w-1/2 border-b border-gray-500 pb-12">
              <button
                className="flex items-center w-full gap-2 justify-center border border-gray-300 rounded-lg p-2 hover:bg-color1 transition-colors hover:text-white duration-300 hover:border-b-2 hover:border-black "
                // onClick={signInWithGoogle}
              >
                <div
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "15px",
                    marginLeft: "5px",
                  }}
                >
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    className="LgbsSe-Bz112c"
                  >
                    <g>
                      <path
                        fill="#EA4335"
                        d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                      ></path>
                      <path
                        fill="#4285F4"
                        d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                      ></path>
                      <path
                        fill="#FBBC05"
                        d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                      ></path>
                      <path
                        fill="#34A853"
                        d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                      ></path>
                      <path fill="none" d="M0 0h48v48H0z"></path>
                    </g>
                  </svg>
                </div>
                <span style={{ marginLeft: "-4px" }}>Continue with Google</span>
              </button>
              <button
                className="flex w-full justify-center items-center gap-2 border border-gray-300 rounded-lg p-2 hover:bg-color1 transition-colors hover:text-white duration-300 hover:border-b-2 hover:border-black "
                // onClick={signInWithGitHub}
              >
                <div style={{ marginRight: "15px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="30px"
                    height="32px"
                  >
                    <path
                      fill="#fff"
                      d="M41,24c0,9.4-7.6,17-17,17S7,33.4,7,24S14.6,7,24,7S41,14.6,41,24z"
                    />
                    <path
                      fill="#455a64"
                      d="M21 41v-5.5c0-.3.2-.5.5-.5s.5.2.5.5V41h2v-6.5c0-.3.2-.5.5-.5s.5.2.5.5V41h2v-5.5c0-.3.2-.5.5-.5s.5.2.5.5V41h1.8c.2-.3.2-.6.2-1.1V36c0-2.2-1.9-5.2-4.3-5.2h-2.5c-2.3 0-4.3 3.1-4.3 5.2v3.9c0 .4.1.8.2 1.1L21 41 21 41zM40.1 26.4C40.1 26.4 40.1 26.4 40.1 26.4c0 0-1.3-.4-2.4-.4 0 0-.1 0-.1 0-1.1 0-2.9.3-2.9.3-.1 0-.1 0-.1-.1 0-.1 0-.1.1-.1.1 0 2-.3 3.1-.3 1.1 0 2.4.4 2.5.4.1 0 .1.1.1.2C40.2 26.3 40.2 26.4 40.1 26.4zM39.8 27.2C39.8 27.2 39.8 27.2 39.8 27.2c0 0-1.4-.4-2.6-.4-.9 0-3 .2-3.1.2-.1 0-.1 0-.1-.1 0-.1 0-.1.1-.1.1 0 2.2-.2 3.1-.2 1.3 0 2.6.4 2.6.4.1 0 .1.1.1.2C39.9 27.1 39.9 27.2 39.8 27.2zM7.8 26.4c-.1 0-.1 0-.1-.1 0-.1 0-.1.1-.2.8-.2 2.4-.5 3.3-.5.8 0 3.5.2 3.6.2.1 0 .1.1.1.1 0 .1-.1.1-.1.1 0 0-2.7-.2-3.5-.2C10.1 26 8.6 26.2 7.8 26.4 7.8 26.4 7.8 26.4 7.8 26.4zM8.2 27.9c0 0-.1 0-.1-.1 0-.1 0-.1 0-.2.1 0 1.4-.8 2.9-1 1.3-.2 4 .1 4.2.1.1 0 .1.1.1.1 0 .1-.1.1-.1.1 0 0 0 0 0 0 0 0-2.8-.3-4.1-.1C9.6 27.1 8.2 27.9 8.2 27.9 8.2 27.9 8.2 27.9 8.2 27.9z"
                    />
                    <path
                      fill="#455a64"
                      d="M14.2,23.5c0-4.4,4.6-8.5,10.3-8.5c5.7,0,10.3,4,10.3,8.5S31.5,31,24.5,31S14.2,27.9,14.2,23.5z"
                    />
                    <path
                      fill="#455a64"
                      d="M28.6 16.3c0 0 1.7-2.3 4.8-2.3 1.2 1.2.4 4.8 0 5.8L28.6 16.3zM20.4 16.3c0 0-1.7-2.3-4.8-2.3-1.2 1.2-.4 4.8 0 5.8L20.4 16.3zM20.1 35.9c0 0-2.3 0-2.8 0-1.2 0-2.3-.5-2.8-1.5-.6-1.1-1.1-2.3-2.6-3.3-.3-.2-.1-.4.4-.4.5.1 1.4.2 2.1 1.1.7.9 1.5 2 2.8 2 1.3 0 2.7 0 3.5-.9L20.1 35.9z"
                    />
                    <path
                      fill="#00bcd4"
                      d="M24,4C13,4,4,13,4,24s9,20,20,20s20-9,20-20S35,4,24,4z M24,40c-8.8,0-16-7.2-16-16S15.2,8,24,8 s16,7.2,16,16S32.8,40,24,40z"
                    />
                  </svg>
                </div>
                <span style={{ marginLeft: "-10px" }}>Continue with GitHub</span>
              </button>
              
            </div>
            <p className="mt-4 text-sm">Have an account? <a href="/auth/signin" className="text-color2 font-medium">Login</a></p>
            {/*<button*/}
            {/*    className="flex w-full justify-center items-center gap-2 border border-gray-300 rounded-lg p-2 hover:bg-color1 transition-colors hover:text-white duration-300 hover:border-b-2 hover:border-black "*/}
            {/*    onClick={signInWithTwitter}*/}
            {/*>*/}
            {/*    <FaTwitter/>*/}
            {/*    <span>Continue with Twitter</span>*/}
            {/*</button>*/}
            {/*<button*/}
            {/*    className="flex w-full justify-center items-center gap-2 border border-gray-300 rounded-lg p-2 hover:bg-color1 transition-colors hover:text-white duration-300 hover:border-b-2 hover:border-black "*/}
            {/*    onClick={signInWithFacebook}*/}
            {/*>*/}
            {/*    <FaFacebook/>*/}
            {/*    <span>Continue with Facebook</span>*/}
            {/*</button>*/}
          </div>
        </main>
      );
};
