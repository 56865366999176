import React, { useState, useEffect } from "react";
import AccordionNew from "../pages/Accordian.js";
import blogsStore from "./store/blogsStore";
import { RxHamburgerMenu } from "react-icons/rx";
import { FaCheck, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaArrowRightLong, FaX } from "react-icons/fa6";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";

export default function MobileAcc({
  courseId,
  setSelectedBlogs,
  setVisitedBlogs,
  visitedBlogs,
  handleBlogClick,
  selectedBlogs,
  handleNextPrev,
  selectedBlog,
  searchCateg,
}) {
  const [openArt, setOpenArt] = useState(false);
  // const [selectedBlog, setSelectedBlog] = useState<any>(null);
  const [copy, setCopy] = useState(false);

  function copyUrl() {
    navigator.clipboard.writeText(
      `https://acehub.ai/article/${selectedBlog.slug}`
    );
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 3000);
  }

  return (
    <>
      <>
        <div
          className={`${
            openArt ? "" : "hidden"
          } lg:hidden fixed left-0 h-[84vh] p-3 px-4 rounded-tr-xl z-20 bg-white w-[80vw] 
            scrollbar pr-5 overflow-y-auto mt-[50px] lg:mt-0`}
        >
          <AccordionNew
            courseId={courseId}
            visitedBlogs={visitedBlogs}
            setSelectedBlogs={setSelectedBlogs}
            setVisitedBlogs={setVisitedBlogs}
            handleBlogClick={handleBlogClick}
            selectedBlogs={selectedBlogs}
          />
        </div>
      </>

      {openArt && (
        <div
          className="lg:hidden w-screen h-screen bg-black bg-opacity-40 backdrop-blur-sm fixed top-0 left-0 z-10"
          onClick={() => setOpenArt(false)}
        />
      )}

      <div
        className="lg:hidden bg-white min-h-[50px] z-10 w-screen flex flex-col overflow-y-auto"
        style={{
          marginTop: "-24px",
        }}
      >
        <div className="flex flex-row justify-between">
          {!openArt && (
            <div className="px-3 py-2 flex gap-3 items-center">
              <button
                onClick={() => setOpenArt((prev) => !prev)}
                className="p-0 focus:outline-none pt-0"
              >
                <RxHamburgerMenu />
              </button>
              <button
                onClick={() => handleNextPrev("prev")}
                className="text-color4 flex p-0 rounded-lg focus:outline-none cursor-pointer"
              >
                <FaChevronLeft />{" "}
                <span style={{ marginTop: "-5px" }}>Prev</span>
              </button>
              <button
                onClick={() => handleNextPrev("next")}
                className="text-color4 flex p-0 rounded-lg focus:outline-none  cursor-pointer"
              >
                <span style={{ marginTop: "-5px" }}>Next</span>
                <FaChevronRight />
              </button>

              {selectedBlog && selectedBlog.title && (
                <h3
                  className="opacity-70 text-sm flex gap-2 items-center"
                  style={{ fontSize: "12px", marginTop: "-4px" }}
                >
                  {selectedBlog.title === "Probability"
                    ? "Probability"
                    : searchCateg(selectedBlog.title)}{" "}
                  <FaArrowRightLong />{" "}
                  {selectedBlog.title === "Probability"
                    ? "Basic Probability"
                    : selectedBlog.title}
                </h3>
              )}
            </div>
          )}
          {openArt && (
            <div className="px-3 py-2 flex gap-3 items-center">
              <button
                onClick={() => setOpenArt((prev) => !prev)}
                className="p-2 focus:outline-none"
              >
                <FaX />
              </button>
            </div>
          )}
          {!openArt && selectedBlog?.slug && (
            <div
              onClick={() => {
                if (!copy) copyUrl();
              }}
              className="px-3 p-1 text-md bg-white rounded-md flex items-center justify-center"
            >
              {copy ? (
                <FaCheck className="text-green-700 cursor-not-allowed" />
              ) : (
                <IosShareOutlinedIcon className="cursor-pointer" />
              )}
            </div>
          )}
        </div>
        <hr />
      </div>
    </>
  );
}
