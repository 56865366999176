import React, {useEffect, useState, useRef} from "react";
import {useParams} from "react-router-dom";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
import rehypeSlug from "rehype-slug";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";
import blogsStore from "./store/blogsStore";
import "highlight.js/styles/github.css";
import 'katex/dist/katex.min.css';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import LoadingPage from "./Helpers/LoadingPage";
import ConfettiGame from "./Helpers/ConfettiGame";
import {components} from "./CustomComponents/CopyCustom.js";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import {FaCheck} from "react-icons/fa";

import styles from "./BlogComp.module.css";
import "./BlogComp.css";


const SlugArticleComp = ({}) => {
	const {slug_id} = useParams(); // Get the slug_id from the URL
	const [showConfettiGame, setShowConfettiGame] = useState(false);
	const [articleContent, setArticleContent] = useState(null);
	// const [loading, setLoading] = useState(true);
	// const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [copy, setCopy] = useState(false);
	const {fetchArticleFromSlug, slugArticleContent} = blogsStore((state) => ({
		fetchArticleFromSlug: state.fetchArticleFromSlug, slugArticleContent: state.slugArticleContent
	}))
	useEffect(() => {
		if (!slugArticleContent) {
			const timer = setTimeout(() => {
				setShowConfettiGame(true);
			}, 2000); // 2 seconds delay
			
			return () => clearTimeout(timer);
		}
	}, [slugArticleContent]);
	
	useEffect(() => {
		if (slugArticleContent) {
			setIsLoading(false);
			setShowConfettiGame(false); // Ensure ConfettiGame doesn't show if content is available
		}
	}, [slugArticleContent]);
	
	useEffect(() => {
		fetchArticleFromSlug(slug_id);
	}, [slug_id]);
	// if (loading) return <div>Loading...</div>;
	// if (error) return <div>{error}</div>;
	function copyUrl(slug_id) {
		navigator.clipboard.writeText(
			`https://acehub.ai/article/${slug_id}`
		);
		setCopy(true);
		setTimeout(() => {setCopy(false);}, 3000);
	}
	
	return (
		!isLoading && slugArticleContent ? (
			<div className="fixed top-24 overflow-y-auto w-screen h-[91vh] ">
			<div className="flex justify-center items-center mx-auto w-full max-w-screen-lg px-4  overflow-y-auto mb-32 py-6 xl:py-12 ">
				<div
					className="prose custom-prose lg:prose-sm w-full max-w-full overflow-y-auto lg:prose-headings:text-base"
					id="check"
					style={{
						height: "100%",
						'--tw-prose-headings': 'rgb(6 54 158 / 91%)',
						'--tw-prose-pre-bg': 'white'
					}}
				>
					{/*<ScrollProgressBar/>*/}
					<div
						className="flex items-center justify-center bg-white rounded-full"
						style={{
							float:"right",
							marginTop:"-8px",
							paddingLeft:"20px",
							height: '40px',             // Increased size for better visibility
							width: '40px',              // Increased size for better visibility
							zIndex: 1000,
							color: 'grey',
							cursor: 'pointer',
						}}
					>
						{copy ? (
							<FaCheck className="text-green-700 cursor-not-allowed"/>
						) : (
							<IosShareOutlinedIcon onClick={() => copyUrl(slug_id)}/>
						)}
					</div>
					<ReactMarkdown
						className={`markdown renderer ${styles.markdown}`}
						remarkPlugins={[remarkGfm, remarkMath]}
						rehypePlugins={[rehypeHighlight, rehypeSlug, rehypeRaw, rehypeKatex]}
						components={components}
					>
						{slugArticleContent}
					</ReactMarkdown>
				</div>
			</div></div>
		) : (
			showConfettiGame ? (
				<ConfettiGame/>
			) : (
				<LoadingPage/>
			)
		)
	);
};

export default SlugArticleComp;
