import { useState } from "react";

const NavItem = ({ label, children }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative group w-full font-semibold rounded-md xl:h-full flex flex-col justify-center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <button
        onClick={() => setIsHovered(!isHovered)}
        className={`nav-button lg:w-max w-full text-left px-2 py-1 lg:items-center lg:justify-center flex rounded-md hover:bg-gray-100 ${
          isHovered ? "bg-gray-300" : ""
        } text-sm bg-opacity-25`}
      >
        {label}
      </button>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={`${isHovered ? "lg:relative top-0 left-0" : "hidden"}`}
      >
        {children}
      </div>
    </div>
  );
};

export default NavItem;
