import React, { Fragment, useState } from "react";
import { Courses, Projects } from "../particles/DataLists";
import CourseCard from "../molecules/CourseCard";
import Footer from "../organs/Footer";
import MemorizationCards from "./Carousels/MemorizationCards";
import trophy from "../../assets/trophy.png";
import EmailSubscribe from "./MailChamp/EmailSubscribe";
import "./home.css";
import ScrollTypingEffect from "./Helpers/ScrollTypingEffect";
import MilestoneTimeline from "./MilestoneTimeline";
import GroupClassRequest from "./Coaching/GroupClassRequest";

import {
  cnnArchitectures,
  seqToSeq,
  reinforcementLearningModels,
  generativeModels,
  unsupervisedLearningModels,
  transferLearningModels,
  explainableAIModels,
  multimodalModels,
  audioGenerationModels,
  videoGenerationModels,
} from "./modelHistoryData";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const [selected, setSelected] = React.useState(""); // Default all projects
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const navigate = useNavigate();
  function onClickSelfStudy(courseId) {
    navigate(`/course/${courseId}`);
  }

  function onClickGroupClass(course) {
    setSelectedCourse(course);
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  return (
    <>
      <main className="pt-1" style={{ marginTop: "88px" }}>
        {/*bg-gradient-to-b from-violet-50*/}
        {/*<div className="flex flex-col gap-10 mt-7 items-center justify-center mb-12 pb-16 pt-12 md:mx-16 -mx-4 rounded-3xl bg-gradient-to-tr from-color3 to-[#212f4c]">*/}
        {/*<div style={{width:"100vw", overflow:"hidden"}} className="bg-gradient-to-tr from-color4 to-color3">*/}
        {/*maxWidth: "1400px", */}
        <div
          className="bg-gradient-to-tr from-color3 to-[#212f4c] rounded-b-3xl"
          style={{
            alignItems: "baseline",
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            width: "100vw",
            overflow: "hidden",
            marginTop: "-3px",
          }}
        >
          <div className="flex flex-col gap-10 mt-6 items-center justify-center mb-12 pb-16 pt-12 md:mx-16 rounded-3xl">
            <div
              className="font-bold text-white flex-col xl:flex-row text-4xl flex flex-wrap items-center justify-center gap-3 text-center mb-0"
              style={{ marginTop: "-20px" }}
            >
              <img
                alt="trophy"
                src={trophy}
                style={{
                  width: "40px",
                  height: "40px",
                  opacity: "0.90",
                  marginTop: "-10px",
                }}
              />
              <div className="font-bold text-white text-4xl flex flex-wrap items-center justify-center gap-3 text-center mb-0">
                Let’s
                <span
                  style={{
                    background:
                      "linear-gradient(90deg, rgb(255, 166, 97), rgb(246, 75, 77), rgb(235, 123, 163)) text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Ace AI
                </span>
                together.
              </div>
            </div>
            <div className="flex items-center flex-col gap-2">
              <div className="flex gap-4 items-center justify-center mb-10">
                <div className="bg-black h-[1px] xl:w-[200px] w-[100px] flex opacity-20" />
                <p className="text-gray-400 text-md opacity-60 text-sm xl:text-md font-semibold ">
                  Essential Courses
                </p>
                <div className="bg-black h-[1px] xl:w-[200px] w-[100px] flex opacity-20" />
              </div>
              <div className="flex flex-row gap-x-10 gap-y-16 items-center justify-center flex-wrap">
                {Courses.slice(0, 8).map((course, index) => {
                  return (
                    <CourseCard
                      key={course.courseId}
                      course={course}
                      onClickSelfStudy={onClickSelfStudy}
                      onClickGroupClass={onClickGroupClass}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="flex items-center justify-center mt-20">
          <h2 className="text-center">Audio Generation Models</h2>
        </div>
        <MilestoneTimeline milestones={audioGenerationModels} />

        <div className="flex items-center justify-center mt-20">
          <h2 className="text-center">Video Generation Models</h2>
        </div>
        <MilestoneTimeline milestones={videoGenerationModels} />

        <div className="flex items-center justify-center mt-20">
          <h2 className="text-center">CNN Architectures</h2>
        </div>
        <MilestoneTimeline milestones={cnnArchitectures} />

        <div className="flex items-center justify-center mt-20">
          <h2 className="text-center">CNN Architectures</h2>
        </div>
        <MilestoneTimeline milestones={cnnArchitectures} />

        <div className="flex items-center justify-center mt-20">
          <h2 className="text-center">Sequence to Sequence</h2>
        </div>
        <MilestoneTimeline milestones={seqToSeq} />

        <div className="flex items-center justify-center mt-20">
          <h2 className="text-center">Reinforcement Learning</h2>
        </div>
        <MilestoneTimeline milestones={reinforcementLearningModels} />

        <div className="flex items-center justify-center mt-20">
          <h2 className="text-center">Generative Models</h2>
        </div>
        <MilestoneTimeline milestones={generativeModels} />

        <div className="flex items-center justify-center mt-20">
          <h2 className="text-center">Unsupervised Learning Models</h2>
        </div>
        <MilestoneTimeline milestones={unsupervisedLearningModels} />

        <div className="flex items-center justify-center mt-20">
          <h2 className="text-center">Transfer Learning Models</h2>
        </div>
        <MilestoneTimeline milestones={transferLearningModels} />

        <div className="flex items-center justify-center mt-20">
          <h2 className="text-center">Explainable AI Models</h2>
        </div>
        <MilestoneTimeline milestones={explainableAIModels} />

        <div className="flex items-center justify-center mt-20">
          <h2 className="text-center">Multi Models</h2>
        </div>
        <MilestoneTimeline milestones={multimodalModels} />

        <div className="mt-20">
          <ScrollTypingEffect />
        </div>
        <div className="flex flex-col gap-8 mt-5 pb-4 min-h-48 px-8 items-center justify-start md:max-w-[85vw] md:mx-auto mb-40">
          <div className="sticky lg:top-28 top-[92vh] shadow-md lg:shadow-none z-10 flex gap-3 bg-[#D9D9D9] lg:w-fit lg:max-w-[70vw] max-w-[90vw] rounded-full p-0.5 overflow-auto">
            <button
              onClick={() => setSelected("")}
              className={`font-semibold rounded-full w-max text-black text-lg px-5 py-2 whitespace-nowrap ${
                !selected ? "bg-white" : ""
              }`}
            >
              All
            </button>
            {[...new Set(Projects.map((course) => course.category))].map(
              (category, index) => {
                return (
                  <button
                    key={index}
                    onClick={() => setSelected(category)}
                    className={`font-semibold rounded-full w-max text-black text-lg px-5 max-w-none whitespace-nowrap py-2 ${
                      selected === category ? "bg-white" : ""
                    }`}
                  >
                    {category}
                  </button>
                );
              }
            )}
          </div>
          {
            <>
              <h3 className="text-2xl text-left w-full font-semibold capitalize">
                {selected || "All"}
              </h3>
              <div className="grid grid-cols-cards gap-6 w-full mb-20">
                {selected
                  ? Projects.filter(
                      (course) => course.category === selected
                    ).map((course, index) => {
                      return (
                        <CourseCard
                          key={course.courseId}
                          course={course}
                          onClickGroupClass={onClickGroupClass}
                          onClickSelfStudy={onClickSelfStudy}
                        />
                      );
                    })
                  : Projects.map((course, index) => {
                      return (
                        <CourseCard
                          key={course.courseId}
                          course={course}
                          onClickGroupClass={onClickGroupClass}
                          onClickSelfStudy={onClickSelfStudy}
                        />
                      );
                    })}
              </div>
            </>
          }
        </div>

        <MemorizationCards />

        <div className="mt-40">
          <EmailSubscribe
            buttonText="Stay Informed"
            btnBackgroundColor="#152f37"
            btnColor="white"
          />
        </div>

        <GroupClassRequest
          isOpen={modalOpen}
          onClose={closeModal}
          course={selectedCourse}
        />
      </main>
      <Footer />
    </>
  );
}
